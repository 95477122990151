/* label focus color */
.input-field input[type=text]:focus + label {
  color: #ff9800 !important;
}

/* label underline focus color */
.input-field input[type=text]:focus {
  border-bottom: 1px solid #ff9800 !important;
  box-shadow: 0 1px 0 0 #ff9800 !important;
}

/* icon focus color */
.input-field .prefix.active {
  color: #ff9800 !important;
}
