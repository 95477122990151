.TodoItem {
    background-color: #fafafa;
    border-radius: 15px;
    position: static;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 24px;
    height: 125px;
    box-shadow: 0px 5px 50px rgba(32, 35, 41, 0.15);
}
  
.TodoItem-p {
    margin: 24px 0 24px 24px;
    width: calc(100% - 120px);
    font-size: 22px;
    line-height: 24px;
    font-weight: 400;
}

.TodoItem-p--complete {
    text-decoration: line-through;
}
  
.Icon {
    cursor: pointer;
    margin: 20px;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
}
  
.Icon-check {
    position: relative;
    left: 12px;
}

.Icon-check--active {
    color: #ffffff;
    background-color: #4caf50;
    border-radius: 30px;
}
  
.Icon-delete {
    size: 20px;
    margin: 20px;
    border-radius: 50px;
    position: relative;
    font-size: 20px;
    color: #fafafa;
    background-color: #ffc947;
    right: 0;
}

.Icon-delete:hover {
    background-color: red;
}
