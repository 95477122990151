.Icon-container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    /* background-color: #CCC; */
}

.Icon-container--check {
    position: relative;
    left: 12px;
 }

.Icon-container--delete {
    position: relative;
    right: 0;
 }
  
.Icon-svg {
    width: 24px;
    height: 24px;
    transition: fill .15s;
}

.Icon-container:hover .Icon-svg--check {
    fill: #4caf50;
}

.Icon-container:hover .Icon-svg--delete {
    fill: red;
}
