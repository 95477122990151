body {
  margin: 0;
  padding: 0;
  background: #e1e2e1;
  color: #1e1e1e;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}

#root {
  margin: 0px;
  position: relative;
  min-height: 100vh;
}

.section {
  width: 90%;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blockSpace {
  margin: 25px;
}
