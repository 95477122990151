form {
    width: 90%;
    border-radius: 20px;
    max-width: 400px;
    background-color: #fff;
    padding: 33px 40px;
    display: flex;
    align-content: center;
    flex-direction: column;
  }
  
  label {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 26px;
    color: black;
  }

  textarea {
    border-radius: 10px;
    background-color: #F9FBFC;
    border: 2px, solid #202329;
    border-radius: 2px;
    box-shadow: 0px 5px 50px rgba(32, 35, 41, 0.25);
    color: #1E1E1F;
    font-size: 20px;
    text-align: center;
    padding: 12px;
    height: 96px;
    width: calc(100%-25px);
    resize: none;
  }
  
  textarea::placeholder {
  color: #a5a5a5;
  font-family: 'Montserrat';
  font-weight: 400;
  border-radius: 10px;
}
  
  textarea:focus {
    outline-color: #61DAFA;
  }
  
  .TodoForm-buttonContainer {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .TodoForm-button {
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    color: #202329;
    font-weight: 400;
    width: 120px;
    height: 48px;
    border-radius: 2px;
    border: none;
    font-family: 'Montserrat';
  }
  
  .TodoForm-button--add {
    background-color: #002f6c;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0px 5px 25px rgba(97, 218, 250, 0.5);
  }
  
  .TodoForm-button--cancel {
    background-color: #aaa;
    color: #fff;
    border-radius: 10px;
  }

.btn:hover, .btn-large:hover, .btn-small:hover {
  background-color: #ffc947;
}

