.CreateTodoButton {
    background-color: #ff9800;
    cursor: pointer;
    position: fixed;
    bottom: 48px;
    right: 48px;
}

.btn-floating:hover {
    background-color: #ffc947;
}